import { TFunction } from "i18next";

export function numberFormat(value: number | undefined | null, digits: number = 0): string {
  if (value === undefined || value === null) {
    return '';
  }
  return value.toFixed(digits);
}

export function dateFormat(date: Date | string | undefined | null,
  showTime?: boolean,
  options?: Intl.DateTimeFormatOptions): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (date === undefined || date === null) {
    return '';
  }
  if (showTime) {
    return date.toLocaleString(undefined, options);
  }
  return date.toLocaleDateString(undefined, options);
}

export function humanFileSize(bytes: number, si=true, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export const formatDateAgo = (dateString: string, t: TFunction<"translation", undefined>): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 7) {
    return t('time.date_format', { date: date.toLocaleDateString() });
  } else if (days > 0) {
    return t('time.days_ago', { count: days });
  } else if (hours > 0) {
    return t('time.hours_ago', { count: hours });
  } else if (minutes > 0) {
    return t('time.minutes_ago', { count: minutes });
  } else {
    return t('time.just_now');
  }
};
