import { Avatar, Collapse, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import React from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAuth } from "react-oidc-context";
import { stringAvatar } from "../common/utils/avatar";
import { OrganizationContext } from "../common/contexts/organization";
import CheckIcon from '@mui/icons-material/Check';
import { version } from "../config";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";

export interface LeftMenuContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LeftMenuContext = React.createContext<LeftMenuContextProps | null>(null);

function LeftMenu() {
  const leftMenuCtx = React.useContext(LeftMenuContext);
  const organizationCtx = React.useContext(OrganizationContext);

  const [organizations, setOrganizations] = React.useState<any[]>([]);

  const [userOpen, setUserOpen] = React.useState(false);

  const auth = useAuth();

  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql`
    query Organizations {
      organizations(order_by: {name: asc}) {
        uuid
        name
      }
    }
  `);

  React.useEffect(() => {
    if (!auth.isAuthenticated || !auth.user || auth.user?.expired) {
      return;
    }

    if (data && data.organizations) {
      const orgs = data.organizations;
      setOrganizations(orgs);
      if (orgs.length === 1) {
        organizationCtx?.setOrganizationID(orgs[0].uuid);
      }
    }
  }, [data]);

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  }

  return (
    <Drawer
      anchor="left"
      open={leftMenuCtx?.open}
      onClose={() => {leftMenuCtx?.setOpen(false)}}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="close menu"
          onClick={() => {leftMenuCtx?.setOpen(false)}}
          sx={{ mr: 2 }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <span style={{
          flexGrow: 1,
        }} />
        <img src="/logotipo.png" alt="logo" style={{
          height: 32,
          width: 'auto',
          maxWidth: '50%',
        }} />
      </Toolbar>
      <List
        sx={{ width: '100%', maxWidth: 270, bgcolor: 'background.paper' }}
        component="nav"
      >
        {/* <ListItemButton href="https://stream.dromt.it" target="_blank" rel="noreferrer" alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemIcon>
            <Avatar>
              <DashboardIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary={t("Dashboard")} />
        </ListItemButton> */}
        <ListItemButton onClick={handleUserClick} alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemIcon>
            <Avatar {...stringAvatar(auth.user?.profile.name ?? auth.user?.profile.email ?? t('User'))} />
          </ListItemIcon>
          <ListItemText primary={auth.user?.profile.name ?? auth.user?.profile.email ?? t('User')} />
          {userOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={userOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            { organizations.map((org) => {
              return <ListItemButton key={ org.uuid } sx={{ pl: 4 }} alignItems="flex-start" onClick={() => {
                if (organizationCtx && org.uuid && organizationCtx?.organizationID == org.uuid) {
                  organizationCtx.setOrganizationID(null);
                  localStorage.removeItem('currentOrganizationId');
                  window.location.reload();
                } else {
                  organizationCtx?.setOrganizationID(org.uuid);
                }
              }} style={{
                alignItems: 'center',
              }}>
                <ListItemText primary={ org.name } />
                { org.uuid == organizationCtx?.organizationID && <ListItemIcon style={{
                  justifyContent: 'end',
                }}>
                  <CheckIcon />
                </ListItemIcon> }
              </ListItemButton>
            }) }
          </List>
        </Collapse>
        <ListItem alignItems="flex-start" style={{
          alignItems: 'center',
        }}>
          <ListItemText secondary={t("version") + " " + version} />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default LeftMenu;
