import React from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const TrackPageView = () => {
  const location = useLocation();

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "pageview",
        page: location.pathname,
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
        referrer: document.referrer,
        timestamp: new Date().toISOString(),
        hostname: window.location.hostname,
        language: navigator.language,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  return null;
};

export default TrackPageView;
