import React from "react";
import useUser from "../hooks/useUser";
import AcquireFeature from "../pages/AcquireFeature";

export interface RoleGuardProps extends React.PropsWithChildren<{}> {
  requiredRoles?: string[];
  featureName?: string;
}

const RoleGuard = (props: RoleGuardProps) => {
  const [ showChildren, setShowChildren ] = React.useState<boolean | undefined>();
  const [ missingRoles, setMissingRoles ] = React.useState<string[]>([]);

  const { hasRole } = useUser();

  React.useEffect(() => {
    if (!props.requiredRoles || props.requiredRoles.length === 0) {
      setShowChildren(true);
      return;
    }
    
    for (const role of props.requiredRoles) {
      const check = hasRole(role);

      if (check === undefined) {
        setMissingRoles([]);
        setShowChildren(undefined);
        return;
      }

      if (!check) {
        setMissingRoles([...missingRoles, role]);
        setShowChildren(false);
        return;
      }
    }

    setMissingRoles([]);
    setShowChildren(true);
  }, [props.requiredRoles, hasRole]);

  if (showChildren === undefined) {
    return null;
  }

  if (!showChildren) {
    return <AcquireFeature name={props.featureName} minRole={missingRoles[0]} />
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default RoleGuard;
